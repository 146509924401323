<template>
  <div class="bitts-select-tags" :class="{ error }">
    <BittsFormLabel v-if="formLabel" :label="formLabel" :disabled="disabled" />
    <ASelect
      :virtual="false"
      ref="tagsInput"
      v-model:value="value"
      data-testid="bitts-select-tags"
      :allow-clear="allowClear"
      :autofocus="autoFocus"
      :disabled="disabled"
      :token-separators
      size="large"
      :mode
      :options
      :popup-class-name="
        (filteredOptions?.length ?? 0) > 0
          ? 'bitts-select-tags__dropdown' +
            (hideSelected ? ' ant-select-dropdown-hide-selected' : '')
          : 'hidden'
      "
      @change="handleChange"
      @search="handleSearch"
      :filter-option="filterOption"
      :get-popup-container="getPopupContainer"
    >
      <template #placeholder>
        <div class="bitts-select__placeholder">
          <div v-if="prefixIcon" class="flex items-center">
            <FontAwesomeIcon
              :icon="prefixIcon"
              :style="{ width: '16px', color: 'currentColor' }"
              class="mr-8"
            />
            {{ placeholder }}
          </div>
          <div v-else>
            {{ placeholder }}
          </div>
        </div>
      </template>
      <template #suffixIcon>
        <FontAwesomeIcon
          :icon="['fak', 'chevron-down']"
          class="text-neutral-accent"
          :style="{ width: '16px', color: 'currentColor' }"
        />
      </template>
      <template #removeIcon>
        <FontAwesomeIcon
          :icon="['fak', 'x-circle']"
          class="text-neutral-accent"
          :style="{ width: '16px', color: 'currentColor' }"
        />
      </template>
      <template #tagRender="props">
        <ATag class="ant-select-selection-item flex" v-bind="{ ...props }">
          <BittsAvatar
            v-if="showAvatar"
            class="mr-8"
            :org="{
              clearbit_domain: props.option.clearbit_domain,
              logo_url: props.option.logo_url,
            }"
            size="x-small"
          />
          {{ props.label }}
          <template #closeIcon>
            <FontAwesomeIcon
              :icon="['fak', 'x-circle']"
              class="text-neutral-accent"
              :style="{ color: 'currentColor' }"
            />
          </template>
        </ATag>
      </template>
      <template #option="props: DefaultOptionType">
        <div class="flex content-center">
          <BittsAvatar
            v-if="showAvatar"
            class="mr-8"
            :org="{
              clearbit_domain: props.clearbit_domain,
              logo_url: props.logo_url,
            }"
            size="small"
          />
          <div
            v-if="!isExistingOption(props.value)"
            class="flex justify-between text-neutral-500"
          >
            <div>+ Add "{{ props.label }}"</div>
            <div> Or hit Enter </div>
          </div>
          <div v-else>{{ props.label }}</div>
        </div>
      </template>
    </ASelect>
    <p v-if="!warningMessage && note" class="bitts-select-tags__note">
      {{ note }}
    </p>
    <BittsAlert
      v-if="warningMessage"
      :message="warningMessage"
      color="warning"
      class="mt-8 mb-16"
    />
  </div>
</template>

<!-- 
  - 'tags' mode supports auto-tokenization, but 'multiple' mode does not.
  - In 'tags' mode, selected options are always visible, whereas 'multiple' mode allows hiding them.
  - The issue arises when we need both auto-tokenization and the ability to hide selected options.
  - To achieve this, we use 'tags' mode and hide selected options with CSS.
  - However, this causes visual bugs due to the virtual scrolling feature of the Ant Design Select component.
  - To resolve these issues, we disable virtual scrolling by setting the 'virtual' prop to false.
-->
<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Select as ASelect, Tag as ATag } from 'ant-design-vue';
import type {
  DefaultOptionType,
  LabeledValue,
  SelectValue,
} from 'ant-design-vue/es/select';
import type { RawValueType } from 'ant-design-vue/es/vc-select/Select';
import { computed, useTemplateRef } from 'vue';

import BittsAlert from '../BittsAlert/BittsAlert.vue';
import BittsAvatar from '../BittsAvatar/BittsAvatar.vue';
import BittsFormLabel from '../BittsFormLabel/BittsFormLabel.vue';

const {
  allowClear = true,
  autoFocus = false,
  disabled = false,
  placeholder = 'Start typing...',
  error = false,
  prefixIcon,
  note,
  warningMessage,
  formLabel,
  mode = 'tags',
  options,
  showAvatar = false,
  tokenSeparators = [',', ' '],
  getPopupContainer = () => document.body,
  hideSelected = true,
} = defineProps<{
  allowClear?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  placeholder?: string;
  error?: boolean;
  prefixIcon?: string | Record<string, unknown> | unknown[];
  mode?: 'tags' | 'multiple';
  note?: string;
  showAvatar?: boolean;
  warningMessage?: string;
  modelValue?: SelectValue;
  options?: DefaultOptionType[];
  formLabel?: Record<string, unknown> | string;
  tokenSeparators?: string[];
  getPopupContainer?: () => HTMLElement;
  hideSelected?: boolean;
}>();

const emit = defineEmits<{
  (event: 'change' | 'update:modelValue', value: SelectValue): void;
  (event: 'search', value: string): void;
}>();

const tagsInput = useTemplateRef<HTMLSelectElement>('tagsInput');

const value = defineModel<SelectValue>('modelValue');

const filteredOptions = computed(() => {
  if (!hideSelected || !value.value) return options;
  const currentValue = value.value;

  return options?.filter((option) => {
    if (!option.value) return true;

    if (typeof currentValue === 'number' || typeof currentValue === 'string') {
      return currentValue !== option.value;
    }
    if (!Array.isArray(currentValue) && 'value' in currentValue) {
      return currentValue.value !== option.value;
    }

    if (Array.isArray(currentValue) && typeof currentValue[0] !== 'object') {
      return !(currentValue as RawValueType[]).includes(option.value);
    }

    if (
      Array.isArray(currentValue) &&
      'value' in (currentValue[0] as LabeledValue)
    ) {
      return !(currentValue as LabeledValue[]).some(
        (item) => item.value === option.value,
      );
    }

    return true;
  });
});

function handleChange(val: SelectValue) {
  emit('change', val);
}

function handleSearch(val: string) {
  emit('search', val);
}

function isExistingOption(newValue?: string | number | null): boolean {
  return options?.some((option) => option.value === newValue) ?? false;
}

function filterOption(input: string, option: DefaultOptionType) {
  return (
    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    !isExistingOption(option.value)
  );
}

defineExpose({ tagsInput });
</script>

<style lang="pcss">
.bitts-select-tags__note {
  @apply text-sm text-neutral-text-weak mt-8;
}

.bitts-select-tags {
  .ant-select {
    font-family: inherit;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-search {
    @apply ms-0;
  }
  .ant-select-multiple .ant-select-selection-item {
    @apply rounded-4 border-none bg-neutral-background text-neutral-text h-24 leading-6;
    .ant-select-selection-item-content {
      @apply pr-4 text-neutral-text;
    }
  }
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
      .ant-pagination-size-changer
    ):hover
    .ant-select-selector {
    @apply border-neutral-border;
  }
  .anticon-close-circle {
    @apply relative bottom-3;
  }
  .ant-select {
    @apply text-base leading-6 w-full;
  }
  .ant-select-selection-selected-value {
    @apply text-neutral-text;
  }

  .ant-select-selection-search {
    @apply ms-0;
  }

  .ant-select-selection-overflow {
    @apply mx-8;
  }
  .ant-select-arrow .ant-select-arrow-icon {
    @apply transition-transform;
  }
  .ant-select-open .ant-select-arrow-icon {
    @apply rotate-180;
  }
  .ant-select-selector {
    @apply flex-nowrap max-h-[160px] overflow-y-auto;
    scrollbar-width: thin;
  }
  .ant-select-clear {
    @apply mr-8;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    @apply rounded-lg border-neutral-border transition-none shadow-component;
  }
  .ant-select-selection-placeholder {
    @apply text-neutral-text-placeholder ml-4;
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    @apply bg-neutral-background-disabled;
  }
  .ant-select-multiple.ant-select-lg .ant-select-selection-item {
    @apply h-24 leading-6;
  }
  .ant-select-selection--multiple .ant-select-selection-item-remove {
    @apply right-8;
  }
  &.error .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none !important;
    border-right-width: 2px !important;
    @apply border-danger-border-focus border-2;
  }
}
.ant-select-dropdown-menu-item,
.ant-select-dropdown-menu-item-group-list
  > .ant-select-dropdown-menu-item.ant-select,
.ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
  @apply text-neutral-text-strong py-5 px-8 rounded;
}

.ant-select-dropdown-menu-item-active:not(
    .ant-select-dropdown-menu-item-disabled
  ) {
}
.ant-select-dropdown-menu-item:hover:not(
    .ant-select-dropdown-menu-item-disabled
  ) {
  @apply bg-neutral-background;
}
.ant-select-selection:focus,
.ant-select-selection:active {
  box-shadow: 0 0 0 1px theme(colors.neutral.border-focus);
  @apply border-neutral-border-focus;
}

.ant-select-dropdown-menu-item-group-title {
  @apply p-0 h-auto;
}
.ant-select-dropdown-menu-item-group:not(:last-child) {
  @apply border-b border-b-neutral-border pb-4 mb-4;
}
.ant-select-dropdown-menu {
  @apply p-4;
}
.ant-select-dropdown-menu-item-group-list
  .ant-select-dropdown-menu-item:first-child:not(:last-child),
.ant-select-dropdown-menu-item-group:not(:last-child)
  .ant-select-dropdown-menu-item-group-list
  .ant-select-dropdown-menu-item:last-child {
  @apply rounded-4;
}

.bitts-select-tags__dropdown {
  @apply border border-neutral-border rounded-8;
  &.ant-select-dropdown--multiple
    .ant-select-dropdown-menu-item-selected
    .ant-select-selected-icon,
  &.ant-select-dropdown--multiple
    .ant-select-dropdown-menu-item-selected:hover
    .ant-select-selected-icon {
    @apply text-info-accent;
  }
  &.ant-select-dropdown--multiple
    .ant-select-dropdown-menu-item:hover
    .ant-select-selected-icon {
    @apply text-neutral-text-strong;
  }
  .ant-select-dropdown-menu-item-active {
    @apply bg-neutral-background;
  }
  .ant-select-dropdown-menu-item-selected {
    @apply bg-info-background-weak font-normal;
  }
}

.ant-select-dropdown-hide-selected .ant-select-item-option-selected {
  @apply hidden;
}

.ant-select-selection-item {
  @apply flex items-center;
}
.ant-tag-close-icon {
  @apply flex;
}
.ant-tag {
  @apply !rounded-bts-lg;
}
.ant-select-item-option-active {
  @apply !bg-primary-background-weak;
}
</style>
